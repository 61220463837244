import React from 'react';

const ContentfulPicture = ({ src, ...rest }: JSX.IntrinsicElements['img']) => {
  const image = src.startsWith('//') ? `https:${src}` : src;
  return (
    <picture>
      <source
        type="image/avif"
        srcSet={`${image}?fm=avif&w=480 800w, ${image}?fm=avif&w=1000 1000w, ${image}?fm=avif 2000w`}
      />
      <source
        type="image/webp"
        srcSet={`${image}?fm=webp&w=480 800w, ${image}?fm=webp&w=1000 1000w, ${image}?fm=webp 2000w`}
      />
      <img src={image} {...rest} />
    </picture>
  );
};

export default ContentfulPicture;
