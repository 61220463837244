import React from 'react';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ContentfulPicture from '../components/ContentfulPicture';
import entities from './entities';

const options = {
  renderMark: {
    // [MARKS.BOLD]: text => <Bold>{text}</Bold>
  },
  renderNode: {
    // eslint-disable-next-line
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    // eslint-disable-next-line
    [BLOCKS.QUOTE]: (node, children) => (
      <blockquote style={{ fontSize: 25, marginTop: 30 }}>{children}</blockquote>
    ),
    // eslint-disable-next-line
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { sys, fields } = node.data.target;
      const type = sys.contentType?.sys.id;

      if (entities[type]) {
        const Entity = entities[type];
        return <Entity {...fields} />;
      }

      return null;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { fields } = node.data.target;
      if (!fields?.file) {
        return null;
      }

      return (
        <ContentfulPicture
          src={fields.file.url}
          alt={fields.file.title}
          width={fields.file.details.image.width / 2}
          height={fields.file.details.image.height / 2}
          // style={{ maxWidth: '100%', maxHeight: 150 }}
        />
      );
    },
  },
};

const renderDocument = (document: Document) => {
  return documentToReactComponents(document, options);
};

export default renderDocument;
