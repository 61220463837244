import React from 'react';
import classNames from 'classnames';
import Link from '../common/Link';

import ContentfulPicture from '../ContentfulPicture';
import styles from './Welcome.module.scss';

const About = ({ image }) => (
  <div className="col-sm-4">
    <div className={classNames('row', styles.homeImage, styles.large)}>
      <div className={styles.item}>
        <ContentfulPicture
          className="img-responsive"
          src={image}
          alt="About Aquinna Homes"
          loading="lazy"
          width={370}
          height={557}
        />
        <Link to="about" className={styles.overlay}>
          <i className="icon-link icon-4x" />
          <div className={styles.caption}>
            <p>
              <strong>
                About Aquinna Homes{' '}
                <span className={styles.icon}>
                  <span
                    className={classNames('icon-chevron-right', styles.chevron)}
                    aria-hidden="true"
                  />
                </span>
              </strong>
            </p>
          </div>
        </Link>
      </div>
    </div>
  </div>
);

const Available = ({ image, content }) => (
  <div className="col-md-4 col-sm-8">
    <div className={classNames('row', styles.homeImage)}>
      <div className={styles.item}>
        <ContentfulPicture
          className="img-responsive"
          src={image}
          alt="Currently Available"
          loading="lazy"
          width={370}
          height={246}
        />
        <Link to="developments" className={styles.overlay}>
          <i className="icon-link icon-4x" />
          <div className={styles.caption}>
            <p>
              <strong>
                Currently Available{' '}
                <span className={styles.icon}>
                  <span
                    className={classNames('icon-chevron-right', styles.chevron)}
                    aria-hidden="true"
                  />
                </span>
              </strong>
            </p>
          </div>
        </Link>
      </div>
    </div>

    <div className="welcome">{content}</div>
  </div>
);

const Contact = ({ image }) => (
  <div className="col-sm-4">
    <div className={classNames('row', styles.homeImage, styles.large)}>
      <div className={styles.item}>
        <ContentfulPicture
          className="img-responsive"
          src={image}
          alt="Contact Us"
          loading="lazy"
          width={370}
          height={557}
        />
        <Link to="contact" className={styles.overlay}>
          <i className="icon-link icon-4x" />
          <div className={styles.caption}>
            <p>
              <strong>
                Contact Us{' '}
                <span className={styles.icon}>
                  <span
                    className={classNames('icon-chevron-right', styles.chevron)}
                    aria-hidden="true"
                  />
                </span>
              </strong>
            </p>
          </div>
        </Link>
      </div>
    </div>
  </div>
);

const Welcome = ({ leftImage, rightImage, centerImage, content }) => (
  <section className="container-home">
    <div className="container introduction clearfix">
      <div className="col-md-12">
        <div className="row hidden-xs hidden-sm hidden-ms">
          <About image={leftImage} />
          <Available content={content} image={centerImage} />
          <Contact image={rightImage} />
        </div>
        <div className="row hidden-md hidden-lg">
          <Available content={content} image={centerImage} />
          <About image={leftImage} />
          <Contact image={rightImage} />
        </div>
      </div>
    </div>

    {/* <div className="fast-track">
      <div className="container">
        <div className="col-sm-2">
          <img className="img-responsive"\
          style={{height: 150, margin: '0 auto'}} src="/images/fast_track.png"
          alt="Aquinna Homes in the Sunday Times Virgin Fast Track 100 2016"/>
        </div>
        <div className="col-sm-10">
          <h2>Aquinna Homes in the Sunday Times Virgin Fast Track 100</h2>
          <p>Aquinna Homes plc is delighted to be recognised in the 2016 'Sunday Times Virgin Fast Track 100' highlighting Britain's private companies with the fastest growing sales over the last three years.</p>
        </div>
      </div>
    </div> */}
  </section>
);

export default Welcome;
