import React from 'react';
import { GoogleMap, useJsApiLoader, Marker, MarkerProps } from '@react-google-maps/api';

const defaultCenter = { lat: 51.507345, lng: -0.127679 };

const containerStyle = {
  width: '100%',
  height: '100%',
};

type Position = {
  lat: number;
  lng: number;
};

interface Props {
  center: Position;
  markers?: MarkerProps[];
  zoom?: number;
}

const Map = ({ center = defaultCenter, markers, zoom }: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDLH5cuhXQHx0FkNeqJLIrGI95qTCoAdSY',
    preventGoogleFontsLoading: true,
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom || 10}
      options={{
        scrollwheel: false,
        mapTypeControl: true,
        scaleControl: true,
        draggable: !('ontouchend' in document),
      }}
    >
      {markers
        ?.filter?.((marker) => marker.position && marker.position.lat && marker.position.lng)
        .map((marker, i) => (
          <Marker key={i} {...marker} />
        ))}
    </GoogleMap>
  ) : (
    <div style={{ textAlign: 'center' }}>
      <span
        className="icon-spinner icon-5x icon-pulse"
        style={{
          marginTop: 212,
        }}
      />
    </div>
  );
};

export default Map;
