import React from 'react';
import { useRouter } from 'next/router';
import { GetStaticPropsContext } from 'next';
import { get } from '../components/common/fetch';
import Header from '../components/home/Header';
import Welcome from '../components/home/Welcome';
import Map from '../components/home/Map';
import renderDocument from '../contentful/renderDocument';
import { getClient } from '../contentful/contentfulClient';
import PreviewMode from '../components/PreviewMode';

export async function getStaticProps(context: GetStaticPropsContext) {
  const MINUTES = 60;
  const revalidate = 60 * MINUTES;

  const client = getClient(context.preview);

  const entries = await client.getEntries({
    content_type: 'homePage',
    include: 10,
  });

  const entry = entries.items && entries.items?.[0];

  if (!entry) {
    return {
      notFound: true,
    };
  }

  let developments;
  try {
    developments = await get('/development/selling');
  } catch {
    //
  }

  return {
    props: {
      developments: developments || [],
      entry,
      ...(context.preview && { preview: context.preview }),
    },
    revalidate: developments ? revalidate : 1, // In seconds
  };
}

const Home = ({ entry, developments, preview }) => {
  const router = useRouter();
  const welcomeText = renderDocument(entry.fields.welcomeText);
  const leftImage = entry.fields.leftImage.fields.file.url;
  const centerImage = entry.fields.centerImage.fields.file.url;
  const rightImage = entry.fields.rightImage.fields.file.url;

  const images = entry.fields.bannerImages.map((image) => image.fields.file.url);

  const onMarkerClick = (development) => {
    if (development.status === 'available') {
      router.push(`/development/${development.permalink}`);
    } else {
      router.push('/forthcoming');
    }
  };

  return (
    <div className="home">
      {preview && <PreviewMode />}
      <Header images={images} />
      {renderDocument(entry.fields.content)}
      <Welcome
        leftImage={leftImage}
        rightImage={rightImage}
        centerImage={centerImage}
        content={welcomeText}
      />
      {developments && <Map developments={developments} onMarkerClick={onMarkerClick} />}
    </div>
  );
};

export default Home;
