import AboutSection from './entity/AboutSection';
import Block from './entity/Block';
import ContactSection from './entity/ContactSection';
import Section from './entity/Section';

const entities = {
  aboutSection: AboutSection,
  contactSection: ContactSection,
  block: Block,
  section: Section,
};

export default entities;
