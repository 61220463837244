import React from 'react';
import renderDocument from '../renderDocument';

const Section = ({ background, container, row, content, center }) => {
  const Container = ({ children }) =>
    container ? (
      <div className="container" style={{ paddingTop: 20 }}>
        {children}
      </div>
    ) : (
      children
    );
  const Row = ({ children }) => (row ? <div className="row">{children}</div> : children);
  return (
    <section style={{ backgroundColor: background, ...(center ? { textAlign: 'center' } : {}) }}>
      <Container>
        <Row>{renderDocument(content)}</Row>
      </Container>
    </section>
  );
};

export default Section;
