import React from 'react';

const PreviewMode = () => {
  const exitPreview = () => {
    const currentPath = window.location.pathname.substr(1);
    window.location.href = `/api/exit-preview?path=${currentPath}`;
  };

  return (
    <div
      style={{
        width: '100%',
        height: 40,
        backgroundColor: 'orange',
        color: 'white',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Viewing site in preview mode.{' '}
      <a onClick={exitPreview} href="#" style={{ textDecoration: 'underline', marginLeft: 20 }}>
        Exit preview
      </a>
    </div>
  );
};

export default PreviewMode;
