import React from 'react';
import renderDocument from '../renderDocument';

const Block = ({ width, content }) => (
  <div className={width?.join(' ')} style={{ textAlign: 'center', marginBottom: 20 }}>
    {renderDocument(content)}
  </div>
);

export default Block;
