import React from 'react';
import renderDocument from '../renderDocument';

const AboutInfo = ({
  children,
  image,
  right,
  trans,
}: {
  image: string;
  right?: boolean;
  trans?: boolean;
  children: any;
}) => (
  <div
    className={`about-info clearfix ${trans ? 'trans' : ''}`}
    style={{ backgroundImage: `url("${image}")` }}
  >
    <div className={`info ${right ? 'pull-right' : ''}`}>{children}</div>
  </div>
);

const AboutSection = ({ image, right, transparent, content }) => (
  <AboutInfo image={image.fields.file.url} right={right} trans={transparent}>
    {renderDocument(content)}
  </AboutInfo>
);

export default AboutSection;
